import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["timer"];

  connect() {
    super.connect();
    console.log("did connect login_controller");
    this.timerTarget.innerText = this.timerTarget.dataset.timetologout;
    this.countdownTimer(this.timerTarget.dataset.timetologout);
  }

  countdownTimer(seconds) {
    const startTime = Date.now();
    const endTime = startTime + seconds * 1000;

    const timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const remainingTime = Math.max(0, endTime - currentTime);

      if (remainingTime === 0) {
        clearInterval(timerInterval);
        this.showmsg("Sie wurden automatisch abgemeldet", 500);
        // window.location.href = "/";
        return;
      }

      const remainingSeconds = Math.ceil(remainingTime / 1000);
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;

      if (remainingSeconds < 300 && !this.didShowWarning) {
        this.showmsg(
          `Sie werden in ${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}
          Minuten automatisch abgemeldet`,
          500,
        );
        this.didShowWarning = true;
        console.log("showed logout warning");
      }

      this.timerTarget.innerText = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }, 1000);

    // Return a function to stop the timer
    return () => clearInterval(timerInterval);
  }
}
