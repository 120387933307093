import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [];

  connect() {
    this.createTooltipElement();
  }

  show(e) {
    let trigger = e.target.closest("[data-title]");
    this.ttelement.innerHTML = trigger.dataset.title;
    this.showTooltip(trigger);
  }

  createTooltipElement() {
    this.ttelement = document.createElement("div");
    this.ttelement.className = "sofrtooltip d-none";
    this.ttelement.innerHTML = "Hello, tooltip!";
    document.querySelector("body").appendChild(this.ttelement);
  }
  showTooltip(trg) {
    this.ttelement.classList.remove("d-none");

    let custom_css = trg.dataset.cc;
    if (custom_css) {
      custom_css.split(" ").forEach((c) => {
        this.ttelement.classList.add(c);
      });
    }

    let screenPadding = 20;

    let screenWidth = window.screen.width * window.devicePixelRatio;
    let screenHeight = window.screen.height * window.devicePixelRatio;
    let scrollX = window.scrollX; // window.pageXOffset || document.documentElement.scrollLeft;
    let scrollY = window.scrollY; // window.pageYOffset || document.documentElement.scrollTop;

    let ypos = trg.getBoundingClientRect().top + scrollY + trg.offsetHeight + 4;
    let xpos = trg.getBoundingClientRect().left + scrollX + trg.offsetWidth / 6;

    let vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    let vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );

    if (xpos + this.ttelement.offsetWidth > screenWidth) {
      xpos = screenWidth - this.ttelement.offsetWidth - screenPadding;
    }

    this.ttelement.style.top = ypos + "px";
    this.ttelement.style.left = xpos + "px";
  }
  hideTooltip() {
    this.ttelement.className = "sofrtooltip d-none";
  }
}
