import "./sofr_frontend.js";

// If you are using JavaScript/ECMAScript modules:
import { Application } from "@hotwired/stimulus";
import ApplicationController from "./controllers/application_controller";

import YformController from "./controllers/yform_controller.js";
import SchulprofilController from "./controllers/schulprofil_controller";
import JourdienstController from "./controllers/jourdienst_controller";
import AufgabentraegerController from "./controllers/aufgabentraeger_controller";
import ErwSLController from "./controllers/erwsl_controller";
import LoginController from "./controllers/login_controller";
// import BSFachbetreuungController from "./controllers/bs_fachbetreuung_controller";
import BSFunktionenplanController from "./controllers/bs_funktionenplan_controller";
import BSVasController from "./controllers/bs_vas_controller";
import SortableController from "./controllers/sortable_controller.js";

Stimulus.register("yform", YformController);
Stimulus.register("sortable", SortableController);
Stimulus.register("schulprofil", SchulprofilController);
Stimulus.register("jourdienst", JourdienstController);
Stimulus.register("aufgabentraeger", AufgabentraegerController);
Stimulus.register("erwsl", ErwSLController);
// Stimulus.register("bsfachbetreuung", BSFachbetreuungController);
Stimulus.register("bsfunktionenplan", BSFunktionenplanController);
Stimulus.register("bsvas", BSVasController);
Stimulus.register("login", LoginController);

// Stimulus.register("bsfachbetreuung", BSFachbetreuungController);
