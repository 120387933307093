import ApplicationController from "./application_controller";
import "sortable-tablesort/sortable-base.css";
import "sortable-tablesort/sortable.min.js";

export default class extends ApplicationController {
  static targets = [];

  connect() {
    console.log("connected sortable_controller.js");
    this.element.classList.add("sortable");
    this.sortby = "";
    if (this.element.dataset.sortby != undefined) {
      this.sortby = this.element.dataset.sortby;
      var xpath = "//th[text()='" + this.sortby + "']";
      var thSort = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null,
      ).singleNodeValue;
      if (thSort != undefined) {
        thSort.setAttribute("aria-sort", "ascending");
      }
    }
  }
}
